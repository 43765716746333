.InfoBox{
	width: 100vw;
	/*height: 50vh;*/
	padding-bottom: 8vh;
	margin: 10vh 0px;
	background-color: white;
	border-radius: 0px;
	-webkit-box-shadow: 0px 0px 20px 6px rgba(0,0,0,0.2); 
	box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.2);
}

.InfoBox2{
	width: 100vw;
	/*height: 50vh;*/
	padding-bottom: 8vh;
	margin: 10vh 0px;
	background-color: white;
	border-radius: 0px;
}

.InfoInnerBox{
	width: 60%;
	margin-left: 20%;
	padding-top: 2vh;
}

.InfoInnerBox .aboutText{
	margin-top: 30px;
	text-align: center;
}

.heading01{
	font-size: 40px;
}
.Bio{
	/*background-image: url("../img/Lago_N-1776.jpg");*/
	background-size: cover;
}

.BioTable{
	width:100%;
	max-width: 800px;
	margin: auto;
	padding: 50px;
	background-color: white;
	border-radius: 0px;
	border: 0px solid black;
	-webkit-box-shadow: 0px 0px 20px 6px rgba(0,0,0,0); 
	box-shadow: 0px 0px 15px 3px rgba(0,0,0,0);
}
.BioTable th,td{
	padding: 15px 10px;
	border-bottom: 1px solid #ddd;
}

.profilepic{
	width: 200px;
	height: 200px;
	border-radius: 100px;
	margin: 50px auto;
	background-image: url("../img/profile.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.BioHeading{
	color: black;
}
.instagram{
	-webkit-box-shadow: 0px 0px 20px 6px rgba(0,0,0,0); 
	box-shadow: 0px 0px 15px 3px rgba(0,0,0,0);
}

@media(max-width:1000px){
	/*.InfoBox .InfoInnerBox .aboutText{
		font-size: 2.5vw;
	}*/
	.profilepic{
		width: 120px;
		height:120px;
	}
	.InfoInnerBox{
		width: 80%;
		margin-left: 10%;
	}
	.heading01{
		font-size: 25px;
	}
	.Bio{
		background-image: none;
		background-color: white;
		background-size: cover;
	}
	.BioHeading{
		color: black;
		font-size: 25px;
	}
	.BioTable{
		padding: 20px;
	}
}

