@charset "utf-8";
/* CSS Document */
body{
	margin: 0;
	overflow-x: hidden;
}


.timestamp01{
	margin: auto;
	width:60vw;
	max-width: 800px;
	background-color: white;
	display: grid;
	grid-template-columns: 30% 70%;
  	justify-content: center;
  	align-items: center;
}

.success{
	margin: auto 20px;
}

.time-flow{
	background-image: url("../img/line.png");
	background-size: contain;
	background-position: center;
	background-repeat:repeat-y;
	width: 100%;
	min-height:200px;
}

.aboutText{
	line-height: 2em;
}
.aboutText a{
	text-decoration: none;
}

.timestamp01 .aboutText{
	margin-left: 20px;
}




@media(max-width:1000px){
	
	.timestamp01{
		width:90%;
		grid-template-columns: 15% 85%;
	}
	.time-left h1{
		font-size: 25px;
	}
	.time-flow{
		height: 200px;
	}
	.aboutText{
		line-height: 24px;
	}
	
	.timestamp01 .aboutText{
		font-size: 16px;
		margin-left: 30px;
	}
	
	
}