
.aboutHeading{
	color: black;
	font-family: 'Open Sans Condensed', sans-serif;
	font-weight: 600;
	font-size: 24px;
	text-align: left;
	letter-spacing: 0;
}
.aboutText{
	color: black;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	line-height: 24px;
}


section h1{
	color: black;
	font-family: 'Open Sans Condensed', sans-serif;
	font-weight: 600;
	font-size: 40px;
	text-align: center;
	letter-spacing: .05em;
	margin-top: 50px;
	margin-block-end:0;
}
section h2{
	color: black;
	font-family: 'Open Sans Condensed', sans-serif;
	font-weight: 300;
	font-size: 30px;
	text-align: center;
	letter-spacing: .1em;
	margin-block-start: 0.3em;
}

section h3{
	color: black;
	font-family: 'Open Sans Condensed', sans-serif;
	font-weight: 300;
	font-size: 20px;
	text-align: center;
	letter-spacing: .1em;
	margin-block-start: 0.3em;
}

/*Media properties für Text*/
@media(max-width:1000px){
	.aboutHeading{
		font-size: 24px;
	}
	
}

.font{
	color: white;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
}
.font2{
	color: white;
	font-family: 'Open Sans Condensed', sans-serif;
	font-weight: 400;
	/*font-family: 'Just Another Hand', cursive;
	/*font-family: 'Permanent Marker', cursive;
	font-weight: bolder;*/
}
.font3{
	color: white;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	letter-spacing: 0em;
	/*font-family: 'Just Another Hand', cursive;
	/*font-family: 'Permanent Marker', cursive;
	font-weight: bolder;*/
}
.maintext{
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	/*background-color: #212121;*/
}