@charset "utf-8";
/* CSS Document */


.footer{
	/*width:80vw;*/
	padding: 5vw 10vw;
	display: grid;
	align-items: center;
	justify-content: left;
	grid-template-columns: repeat(3,1fr);
	background-color: black;
}

.footer01{
	display: grid;
	align-items: center;
	justify-content: left;
	grid-template-columns: repeat(1);
	gap: 2.5vw;
}

.footerElement{
	color: white;
	text-decoration: none;
}

.footerElement:hover{
	opacity: 0.8;
}

.footerImage{
	background-image: url("../img/N2.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: auto;
	height: 50px;
}
.footerIcon{
	width: 30px;
	height: 30px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}
.footerRow{
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2,1fr);
	transition: 0.3s;
}
.footerRow:hover{
	opacity: 0.8;
}

/*Media properties für Footer*/
@media(max-width:1000px){
	.footer{
		grid-template-columns: repeat(1,1fr);
		justify-content: center;
		gap: 15vw;
	}
	.footer01{
		justify-content: center;
	}
	.footerElement{
		text-align: center;
	}
	.footer01:last-of-type{
		padding-bottom: 5vw;
	}
	
}


