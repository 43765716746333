.divider{
    height: 3px;
    background-color: rgb(44, 42, 42);
}

.blogs{
    background-color: rgb(39, 39, 39);
    padding: 0;
}

.topImg{
    width: 100vw;
    height: 80vh;
    background-size: cover;
}

.toBlogButton{
	background-color: rgba(55,55,55,1);
    color: white;
    border: none;
    text-decoration:none;
    cursor: pointer;
    padding:10px;
    text-align: center;
	border-radius: 30px;
    -webkit-box-shadow: 0px 2px 20px 3px rgba(50,50,50,0.2); 
	box-shadow: 0px 2px 20px 3px rgba(50,50,50,0.2);
    margin-bottom: 0px;
    font-size: 15px;
}

.toBlogButton:hover{
    background-color: rgba(85,85,85,1);
    transition: 0.25s;
	transform: translateY(-2px);
}

.blogsMobile{
    visibility: hidden;
    height: 0;
    margin: 0;
}

.videoContainer{
    width: 800px;
    height: 450px;
    margin: auto;
    margin-bottom: 100px;
}
.video{
    width: 100%;
	height:100%;
	position: relative;
    overflow: hidden;
    margin: auto;
}
.video iframe,
.video object,
.video embed {
   	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.gallery{
    width: 90vw;
    max-width: 1400px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
    cursor: pointer;
    margin-bottom: 100px;
}
.galleryImg{
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.galleryImg:hover{
    scale: 1.01;
    transition: 0.5s;
}

.imageView{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0,0,0,0.9);
    z-index: 1;
    display: grid;
    grid-template-rows: 8fr 1fr;
    justify-content: center;
    align-items: center;
}
.currentImg{
    width: 80vw;
    margin:auto;
    margin-top: 100px;
    height: 80%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.arrows{
    font-size: 48px;
    max-width: 800px;
    margin: auto; 
    color: white;
    margin-bottom: 50px;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    grid-gap:100px;
}
.arrow{
    cursor: pointer;
}

.blogBody p{
    margin-bottom: 20px;
}

.blogBody h3{
    margin-bottom: 20px;
}


@media(max-width:1000px){

    .topImg{
        background-position: center;
        height: 40vh;
        margin-top: 50px;
        background-attachment: scroll;
    }

    .blogs{
        padding: 80px 0 50px 0;
    }
    .blogsMobile{
        margin-top: 50px;
        height: fit-content;
    }
    .divider{
        visibility: hidden;
        height: 0;
    }
    .videoContainer{
        width: 90vw;
        height: fit-content;
    }
    .video{
        width: 90vw;
        height: 50vw;
    }

    .gallery{
        grid-template-columns: 1fr;
    }
    .arrows{
        width: 60%;
        margin: auto;
        font-size: 48px;
        margin-bottom: 120px;
    }
    .currentImg{
        width: 95vw;
    }

}

