@charset "utf-8";
/* CSS Document */
body{
	margin: 0;
	overflow-x: hidden;
}

.padding{
	width: 100vw;
	height: 10vh;
	background-color: white;
	z-index: 1;
}




/* Essentials*/

.container-type-01{
	padding: 10vh 0;
	width:100vw;
	/*height:100vh;*/
	/*background-color: rgba(38,38,38,1.00);*/
	background-color: white;
	display: grid;
	grid-template-rows: 100%;
  	justify-content: center;
  	align-items: center;
	
}

.standaloneText{
	height:90%;
	width:80%;
	padding-top: 50px;
	margin: auto;
	overflow:hidden;
	/*background-color: burlywood;*/
}

.VideoContainer{
	width: 60vw;
	border-radius: 0px;
	margin: auto;
}
.Video{
	width: 100%;
	height:0;
	padding-bottom: 56.25%;
	position: relative;
	border-radius: 0px 0px 0px 0px;
	overflow: hidden;
	-webkit-box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.3); 
	box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.3);
}
.Video iframe,
.Video object,
.Video embed {
   	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


#aboutVideo{
	/*height:140vh;*/
	background-color: white;
}

/*Media properties für Video*/
@media(max-width:1000px){
	.VideoContainer{
		width: 85vw;
	}
	.container-type-01{
		grid-template-rows: 100%;
	}
	#aboutVideo{
		/*height:60vh;*/
		max-height: 600px;
	}
	.padding{
		background-color: black;
	}
	
}