
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root{
  overflow-x: hidden;
}

body{
	overflow-x: hidden;
}

nav {
  display: flex;
	position: fixed;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  
  font-family: "Open Sans", sans-serif;
	z-index: 5;
	width: 100vw;
}
.topnav{
	background-color: rgba(0,0,0,1);
	transition: all 0.3s;
}
.topBlack{
	background-color: rgba(0,0,0,1)
}

.logo {
  color: rgb(226, 226, 226);
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 20px;
  background-image: url("../img/SignatureW.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 5vh;
  width: 20%;
}

.nav-links {
  display: flex;
  width: 50%;
  justify-content: space-around;
}
.nav-links li {
  list-style: none;
}

.nav-links a {
  color: rgb(226, 226, 226);
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: 600;
  font-size: 14px;
}
.nav-links a:hover{
	color: #858585;
	transition-duration: 0.5s;
}


.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: rgb(226, 226, 226);
  margin: 5px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1000px) {
  body {
    overflow-x: hidden;
  }
	.topnav{
	background-color: rgba(0,0,0,1);
	transition: all 0.3s;
	}
  .nav-links {
    position: absolute;
    right: 0px;
	  padding: 9vh 0vh;
    height: 92vh;
    top: 8vh;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: translateX(100%);
    transition: transform 1s ease;
  }
  .nav-links li {
    opacity: 1;
		font-size: 20px;
	}
  .burger {
    display: block;
  }
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
  background-color: rgb(160, 20, 20);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
  background-color: rgb(160, 20, 20);
}
