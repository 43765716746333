/* Blog */
* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.blog{
    display: flex;
    width: 100vw;
    margin: 100px 0;
}

.blogHeading{
    padding-top: 60px;
    width: 90vw;
    margin:auto;
    max-width: 800px;
    display: grid;
    grid-gap: 30px;
    text-align: justify;
    text-justify: inter-word;
    
}

@media(max-width:1000px){
    .blogHeading{
        width: 80%;
    }
}

.blogBox{
    /*height: 600px;*/
    width: 80vw;
}

.blogPosts{
    overflow: hidden;
    display: flex;
    width: fit-content;
    transition: transform 0.7s;
}

.blogPost{
    width:500px;
    margin-right: 50px;
    background-color: black;
    color: white;
    border-radius: 30px;
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
}

.blogIMG{
    width: 100%;
    height: 300px;
    border-radius: 30px 30px 0 0;
    background-image:
            linear-gradient(180deg,
              rgba(0,0,0, 0),
              rgba(0,0,0, 1)), url(
'../img/Medien/IM-Bilder-7.jpg');
    background-size: cover;
    background-position: center;
    
}
.blogText{
    width: 80%;
    margin: 10%;
    display: grid;
    grid-template-rows: repeat(1fr);
    grid-gap: 30px;
    text-align: justify;
    text-justify: inter-word;
}
.blogText h2{
    text-align: left;
}

.blogArrowRight{
    z-index: 1;
    width: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 24px;
    background-color: rgba(255,255,255,0.9);
    cursor: pointer;
}


.blogArrowLeft{
    z-index: 1;
    width: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 24px;
    background-color: rgba(255,255,255,0.9);
    cursor: pointer;
}

.mobileBlog{
	width: 0;
	height:0;
    visibility: hidden;
}


@media(max-width:1000px){

    .blog{
        margin-bottom: 50px;
    }

    .blogBox{
        width: 100%;
    }

    .blogPosts{
        visibility: hidden;
        height: 0;
    }

    .mobileBlog{
        visibility: visible;
        width:90%; 
        margin: auto;
        height: fit-content;
        background-color: black;
        color: white;
        border-radius: 30px;
        -webkit-user-select: none;  
        -moz-user-select: none;    
        -ms-user-select: none;      
        user-select: none;
        padding-bottom: 5px;
    }

    .blogArrowLeft{
        width: 0;
    }
    .blogArrowRight{
        width: 0;
    }
}

/* Leidenschaft Triathlon */

.imageBox{
    width:100vw;
    height:100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;

}

.leftBox{
    width: 30vw;
    min-width: 500px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
}

.textBox{
    margin:auto;
    width: 80%;
    color: white;
    padding: 20px 30px 20px 30px;
    border-radius: 30px;
    display: grid;
    grid-template-rows: repeat(1fr);
    grid-gap: 30px;
    text-align: justify;
    text-justify: inter-word;
}

.textBox h2{
    text-align: left;
}
@media(max-width:1000px){

    .imageBox{
        visibility: hidden;
        height: 0;
    }

    .leftBox{
        width: 100%;
        min-width: 0;
    }
    .textBox{
        width: 70%;
        padding:0;
    }


}

.blogButton{
    width: 90vw;
    max-width: 400px;
    margin:auto;
	background-color: rgba(255,255,255,1);
    color: black;
    text-decoration:none;
    cursor: pointer;
    padding:10px;
    text-align: center;
	border-radius: 30px;
    -webkit-box-shadow: 0px 2px 20px 3px rgba(50,50,50,0.2); 
	box-shadow: 0px 2px 20px 3px rgba(50,50,50,0.2);
    margin-bottom: 50px;
}

.blogButton:hover{
    transition: 0.25s;
	transform: translateY(-2px);
}

.loading{
    width: 100vw;
    height: 100vh;
    background-color: black;
}

.fadeIn {
    animation: fadeInAnimation ease 0.8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

label{
    width: 100%;
    margin-right: 20px;
}

input {
    width: 100%;
    border: 2px solid #bdbdbd;
    border-radius: 10px;
    outline: none;
    padding: 5px;
    margin-top: 10px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
input:focus{
    border: 2px solid #646464;
}

select {
    width: 100%;
    border-radius: 10px;
    border-color: black solid;
    padding: 5px;
    margin-top: 10px;
}

.fanclubForm{
    width: 100%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: left;
    align-items: center;
    grid-row-gap: 10px;
    grid-column-gap: 5%;
}
@media(max-width:1000px){

    .fanclubForm{
        grid-template-columns: 100%;
    }

}

.fanclubRegist{
    padding: 30px;
    -webkit-box-shadow: 0px 2px 20px 3px rgba(50,50,50,0.2); 
	box-shadow: 0px 2px 20px 3px rgba(50,50,50,0.2);
    border-radius: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.fanclubRegist h3{
    margin-top: 30px;
    margin-bottom: 10px;
}

.registButton{
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    border: 0px;
    /*border: 1px #646464 solid;*/
    -webkit-box-shadow: 0px 2px 20px 3px rgba(50,50,50,0.2); 
	box-shadow: 0px 2px 20px 3px rgba(50,50,50,0.2);
    background-color: white;
    width: 100%;
    font-size: 14px;
}
.registButton:hover{
    transition: 0.5s;
	transform: translateY(-2px);
    
}