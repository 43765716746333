.imageContainer{
	width: 80vw;
	max-width: 600px;
	margin-bottom: 50px;
	margin: auto;
	display: grid;
	grid-template-rows: repeat(1);
	justify-content: left;
}
.downloadButton{
	margin-right: auto;
	margin-top: 10px;
	margin-bottom: 50px;
	padding: 10px;
	background-color: rgba(0,0,0,0.7);
	border-radius: 5px;
	transition: 0.7s;
}
.downloadButton:hover{
	transform: translateY(-3px);
	background-color: rgba(0,0,0,1);
}
.imagePreview{
	height: 400px;
	width: 100%;
	background-size: cover;
	background-position: center;
	margin-bottom: 50px;
	display: flex;
	flex-direction: column-reverse;
}
.downloadImage{
	width: 100%;
	height: auto;
}

@media(max-width:1000px){

	.imageContainer{
		width: 80%;
	}

	.imagePreview{
		height: 400px;
	}
}