@charset "utf-8";
/* CSS Document */
body{
	margin: 0;
	overflow-x: hidden;
}


.sponsor-type01{
	width:90vw;
	max-width: 800px;
	min-height: 200px;
	margin: auto;
	background-color: white;
	margin-top: 30px;
	margin-bottom: 60px;
}

.sponsor01-container{
	width: 100%;
	height: 90%;
	background-color: white;
	border-radius: 0px;
	display: grid;
	grid-template-columns: 30% 70%;
	/*-webkit-box-shadow: 0px 0px 20px 6px rgba(0,0,0,0.1); 
	box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.1);*/
	border: 0px solid grey;
	justify-content: center;
  	align-items: center;
	text-decoration: none;
	transition: 0.5s;
}
.sponsor01-container:hover{
	-webkit-box-shadow: 0px 0px 20px 6px rgba(0,0,0,0.15); 
	box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.15);
	transform: scale(1.03);
}

.sponsor02-container{
	width:90vw;
	max-width: 800px;
	height: 90%;
	background-color: white;
	border-radius: 0px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-content: center;
  	align-items: center;
	text-decoration: none;
	grid-gap: 15px;
}

.goenner{
	grid-template-columns: 1fr 1fr 1fr;
	height: 50%;
}

.sponsor01-Text{
	/*height:90%;*/
	width:80%;
	margin: auto;
	margin-bottom: 30px;
	margin-top: 30px;
}

.sponsor01-Image{
	background-size:contain;
	background-color:white;
	background-repeat: no-repeat;
	background-position: center;
	width:90%;
	height:90%;
	margin: auto;
}
.sponsor02-Image{
	border: 0px solid grey;
	transition: 0.4s;
	height: 250px;
}
.sponsor02-Image:hover{
	/*-webkit-box-shadow: 0px 0px 20px 6px rgba(0,0,0,0.2); 
	box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.2);*/
	transform: scale(1.05);
}

.sponsorheads{
	width: 100%;
	text-align: center;
	margin: 20px 0px;
	padding: 30px 0px;
	color: black;
	background-color: white;
	-webkit-box-shadow: 0px 0px 20px 6px rgba(0,0,0,0.15); 
	box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.15);
}

@media(max-width:1000px){

	.sponsor-type01{
		width:80vw;
		margin-bottom: 50px;
	}
	
	.sponsor01-container{
		grid-template-columns: 1fr;
		grid-gap: 10px;
		
	}
	.sponsor01-Image{
		height: 100px;
		margin-top: 20px;
	}

	.sponsor02-Image{
		width: 60%;
		height: 100px;
	}
	
	.sponsor02-container{
		width: 80%;
		margin: auto;
		grid-template-columns: repeat(1,1fr);
		grid-gap: 30px;
		height: 80%;
	}
	
	.sponsor01-Text .aboutText{
		font-size: 3vw;
	}
	
}