@charset "utf-8";
/* CSS Document */
body{
	margin: 0;
	overflow-x: hidden;
}

.blank{
	width: 100vw;
	height: 100vh;
	background-color: antiquewhite;
	display: grid;
	align-items: center;
	justify-content:center;
	grid-template-columns: 100vw;
	grid-template-rows: 100vh;
	z-index: 1;
}
.standaloneText{
	height:90%;
	width:80%;
	padding-top: 50px;
	margin: auto;
	overflow:hidden;
	/*background-color: burlywood;*/
}

/* Version 2 */


.container-type01{
	padding: 100px 0 0 0;
	width:100vw;
	/*background-color: rgba(38,38,38,1.00);*/
	background-color: white;
	display: grid;
	grid-template-rows: 100%; /*15% 85%;*/
  	justify-content: center;
  	align-items: center;
	
}

/*
.aboutHeading{
	color: black;
	font-family: 'Open Sans Condensed', sans-serif;
	font-weight: 600;
	font-size: 30px;
	text-align: left;
	letter-spacing: 0;
}
.aboutText{
	color: black;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	font-size: 1.1vw;
}*/


.innerContainer-type01{
	width: 90vw;
	max-width: 1400px;
	background-color: white;
	border-radius: 0px;
	display: grid;
	grid-template-columns: 50% 50%;
	-webkit-box-shadow: 0px 0px 20px 6px rgba(0,0,0,0.1); 
	box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.1);
	border-radius: 30px;
}

.container-01-Image{
	background-size:cover;
	background-color:white;
	width:100%;
	border-radius: 30px 0px 0px 30px;
}

.Right{
	border-radius: 0px 0px 0px 0px;
}



.container-01-Text{
	width:60%;
	margin: auto;
	padding: 50px 0px;
	overflow:hidden;
	text-align: justify;
    text-justify: inter-word;
}

.Grid3{
	padding: 50px 0px;
	margin-left: 10vw;
	width: 80vw;
	display: grid;
	grid-template-columns: repeat(3,1fr);
	align-items: center;
	justify-content: space-between;
}

.Grid3Image{
	width: 15vw;
	height: 20vw;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	margin: auto;
}


.form{
	padding: 50px;
	max-width: 600px;
	-webkit-box-shadow: 0px 0px 20px 6px rgba(0,0,0,0.1); 
	box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.1);
	margin:auto;
}


.inputField{
	padding: 12px 20px;
	border: 3px solid rgba(95,95,95,0.20);
  	border-radius: 10px;
	-webkit-transition: 0.5s;
  	transition: 0.5s;
}
.inputField:focus{
	border: 3px solid rgba(95,95,95,0.50);
	outline: none;
}

.standaloneButton{
	padding: 15px 30px;
	font-size: 24px;
	width: auto;
	max-width: 360px;
	margin: 0px auto;
	border-radius: 10px;
	text-align: center;
	background-color: rgba(0,0,0,0.8);
}


.gapimage{
	margin-top: 50px;
	width: 100vw;
	height: 70vh;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-image: url("../img/Medien/IM-Bilder-15.jpg");
}

/*Media properties für About Jari*/
@media(max-width:1000px){
	
	.container-type01{
		padding: 0;
		padding-top: 50px;
	}

	.standaloneButton{
		max-width: none;
		margin: 0px 20px;
	}
	
	.innerContainer-type01{
		width: 90%;
		margin: auto;
		height: 95%;
		grid-template-columns: 100%;
		grid-template-rows: fr fr;
		border-radius: 30px;
	}
	.container-01-Image{
		border-radius: 0px 0px 0px 0px;
		background-position: center;
		min-height: 400px;
		border-radius: 30px 30px 0px 0px;
	}
	.Right{
		border-radius: 0px 0px 0px 0px;
	}
	.container-01-Text{
		width: 80%;
		margin-bottom: 50px;
		border-radius: 30px;
	}
	
	.Grid3{
		grid-template-columns: repeat(1,3fr);
		row-gap: 25px;
	}
	.Grid3Image{
		width: 50vw;
		height: 30vw;
	}
	.gapimage{
		height: 55vh;
		background-attachment: scroll;
	}
}

/*Images*/

.I01{
	background-image: url("../img/Medien/IM-Bilder-48.jpg");
	background-position: center;
}

.I02{
	background-image: url("../img/JariPortrait01-170.jpg");
	background-position: top;
}



