@charset "utf-8";
/* CSS Document */
/* Banner */

.startupimage{
	width:100%;
	height:100vh;
	background-color: black;
	background-image: url("../img/JariPortrait01-52.jpg");
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;

}

.startupimage:before {
    content: '';
    position: absolute;
    top: 20vh;
    right: 0;
    bottom: 0vh;
	left: 0;
	background-image: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,1));
    /*opacity: .8;*/
	opacity: .8;
		
}

.startBox{
	/*width: 50vw;*/
	position:absolute;
	margin-top: -300px;
	margin-left: 100px;
	display: grid;
	grid-gap: 10px;
	/*background-color: rgba(0,0,0,0.7);*/
	
}
.startText{
	color: white;
	font-size: 72px;
	/*background-color: rgba(0,0,0,0.7);*/
	/*width: 100%;*/
	/*margin: 0;*/
}
.aboutStart{
	color:white;
	/*background-color: rgba(152,21,21,1.00);*/
	/*width: 60%;*/
	margin: 0px 0px 20px 0px;
	font-weight: 600;
	max-width: 30vw;
}
.startButton{
	padding:10px;
	cursor:pointer;
	background-color: rgba(255,255,255,1);
	color: black;
	text-align: center;
	border-radius: 30px;
	/*width:20%;
	max-width:400px;
	min-width: 200px;
	cursor: pointer;
	/*-webkit-box-shadow: 0px 0px 20px 6px rgba(0,0,0,0.4); 
	box-shadow: 0px 0px 20px 6px rgba(0,0,0,0.4);*/
}

.startButton:hover{
	color: black;
	background-color: rgba(255,255,255,0.6);
	transition: 0.25s;
	transform: translateY(-2px);
}
@media(max-width:1000px){
	
	.startBox{
		width: 80%;
		margin-left: 10%;
		margin-bottom: 0px;
		margin-top: -200px;
	}

	.startText{
		padding-left: 0;
		font-size: 48px;
	}
	.aboutStart{
		height:0;
		opacity: 0;
		visibility: hidden;
	}
	.startupimage{
		/*background-image: url("img/StartMobile.png");*/
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: scroll;
		height: 92vh;
	}
	.startupimage:before {
      	position: absolute;
		  background-image: -webkit-linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,1));
		  background-image: -o-linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,1));
		  background-image: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,1));
		opacity: 0;
	}
}

/*Buttons*/

.button{
	border-radius: 10px;
	border: solid;
	/*border-width: 3px;
	border-color: #B90D10;
	background-color: #B90D10;*/
	border-width: 0px;
	border-color: #999999;
	color: white;
	background-color: rgba(255,255,255,0.1);
}
.button:hover{
	/*background-color: #B90D10;
	border-color: #D93033;*/
	border-color: #777777;
	color: #D6D6D6;
	background-color: rgba(255,255,255,0.2);
	transition: 0.25s;
}
.buttonTXT{
	margin-block-start: 0em;
	margin-block-end: 0em;
	font-size:30px;
	color: white;	
}
.buttonTXT2{
	margin-block-start: 0em;
	margin-block-end: 0em;
    margin-inline-start: 0px;
	font-size:20px;	
	text-decoration: none;
}

.center{
	display: flex;
	justify-content: center;
	align-items: center;
}
@media(max-width:1000px){
	.buttonTXT2{

		font-size:5vw;	

	}
	.button{
		border-radius: 0;
		border-width: 2px;
		border-color: #C7C7C7;
		color: white;
	}
	.button:hover{
		border-color: #777777;
		color: #D6D6D6;
		background-color: rgba(0,0,0,0.7);
		transition: 0.25s;
	}
}